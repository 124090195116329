var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "UserProfileAuthority column",
      attrs: { "data-cy": "user-profile-authority" }
    },
    [
      _c("div", { staticClass: "row row--justify-between" }, [
        _c("label", { staticClass: "fs-18 fw-med mb-13" }, [
          _vm._v("Authority")
        ]),
        _c(
          "button",
          {
            staticClass: "fc-light fs-14",
            attrs: { "data-cy": "user-profile-authority-edit-btn" },
            on: {
              click: _vm.nowEditing,
              keydown: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.nowEditing($event)
              }
            }
          },
          [_vm._v("\n      Edit\n    ")]
        )
      ]),
      _c(
        "transition",
        { attrs: { mode: "out-in", name: "Transition__opacity-fade" } },
        [
          !_vm.editing
            ? _c("div", { key: "base", staticClass: "row row--wrap" }, [
                _c("div", { staticClass: "column column--width-auto" }, [
                  _c("label", { staticClass: "fc-light fs-12 fw-med mb-7" }, [
                    _vm._v("MC")
                  ]),
                  _c("label", { staticClass: "fs-14 uppercase" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.pagePOV === "client"
                            ? _vm.user.mc || "N/A"
                            : _vm.user.broker.mc
                        ) +
                        "\n        "
                    )
                  ])
                ]),
                _c("div", { staticClass: "column column--width-auto" }, [
                  _c("label", { staticClass: "fc-light fs-12 fw-med mb-7" }, [
                    _vm._v("DOT")
                  ]),
                  _c("label", { staticClass: "fs-14 uppercase" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.pagePOV === "client"
                            ? _vm.user.dot
                            : _vm.user.broker.dot
                        ) +
                        "\n        "
                    )
                  ])
                ]),
                _c("div", { staticClass: "column column--width-auto" }, [
                  _c("label", { staticClass: "fc-light fs-12 fw-med mb-7" }, [
                    _vm._v("AUTHORITY DATE")
                  ]),
                  _c("label", { staticClass: "fs-14" }, [
                    _vm._v(_vm._s(_vm.authorityDate))
                  ])
                ]),
                _vm.pagePOV === "debtor"
                  ? _c("div", { staticClass: "column column--width-auto" }, [
                      _c(
                        "label",
                        { staticClass: "fc-light fs-12 fw-med mb-7" },
                        [_vm._v("AUTHORITY STATUS")]
                      ),
                      _c(
                        "label",
                        {
                          class: [
                            "capitalize fs-14",
                            {
                              "fc-red":
                                _vm.user.broker.authority_status === "inactive"
                            }
                          ]
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.user.broker.authority_status) +
                              "\n        "
                          )
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm.pagePOV === "client"
                  ? _c("div", { staticClass: "column column--width-auto" }, [
                      _c(
                        "label",
                        { staticClass: "fc-light fs-12 fw-med mb-7" },
                        [_vm._v("EMAIL")]
                      ),
                      _c("label", { staticClass: "fs-14" }, [
                        _vm._v(_vm._s(_vm.user.email))
                      ])
                    ])
                  : _vm._e()
              ])
            : _c("div", { key: "edit", staticClass: "column" }, [
                _c("div", { staticClass: "row row--wrap" }, [
                  _c(
                    "div",
                    { staticClass: "mr-6" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "fc-light fs-12",
                          attrs: { for: "authority-date" }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.duplicateUser.authority_date.valid
                                  ? "Authority Date"
                                  : _vm.instructions.AUTHORITY_DATE_INSTRUCTION
                              ) +
                              "\n          "
                          )
                        ]
                      ),
                      _c("base-date-picker", {
                        staticClass: "mt-7",
                        attrs: {
                          id: "authority-date",
                          valid: _vm.duplicateUser.authority_date.valid
                        },
                        model: {
                          value: _vm.duplicateUser.authority_date.value,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.duplicateUser.authority_date,
                              "value",
                              $$v
                            )
                          },
                          expression: "duplicateUser.authority_date.value"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.pagePOV === "debtor"
                    ? _c(
                        "div",
                        { staticClass: "mr-6" },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "fc-light fs-12",
                              attrs: { for: "authority-status" }
                            },
                            [
                              _vm._v(
                                "\n            Authority Status\n          "
                              )
                            ]
                          ),
                          _c("v-select", {
                            staticClass:
                              "UserProfileAuthority__authority-select mt-7",
                            attrs: {
                              "aria-label": "Authority Status Select Input",
                              clearable: false,
                              "data-cy":
                                "user-profile-authority-authority-status",
                              options: _vm.authorityStatusOptions,
                              placeholder: "Authority Status"
                            },
                            model: {
                              value: _vm.duplicateUser.authority_status.value,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.duplicateUser.authority_status,
                                  "value",
                                  $$v
                                )
                              },
                              expression: "duplicateUser.authority_status.value"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.pagePOV === "client"
                    ? _c(
                        "div",
                        [
                          _c(
                            "base-input",
                            {
                              staticClass: "mr-6",
                              attrs: {
                                instructions: _vm.duplicateUser.email.unique
                                  ? _vm.instructions.EMAIL_INSTRUCTION
                                  : _vm.instructions.EMAIL_UNIQUE_INSTRUCTION,
                                label: true,
                                "label-for": "email",
                                valid:
                                  _vm.duplicateUser.email.unique &&
                                  _vm.duplicateUser.email.valid
                              },
                              model: {
                                value: _vm.duplicateUser.email.value,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.duplicateUser.email,
                                    "value",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "duplicateUser.email.value"
                              }
                            },
                            [_vm._v("\n            Email\n          ")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]),
                _c(
                  "div",
                  { staticClass: "row mt-12" },
                  [
                    _c(
                      "base-button",
                      {
                        staticClass: "bg-blue fc-white mr-6",
                        attrs: { "data-cy": "user-profile-authority-save-btn" },
                        on: {
                          click: _vm.saveUserDetails,
                          keydown: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.saveUserDetails($event)
                          }
                        }
                      },
                      [_vm._v("\n          Save\n        ")]
                    ),
                    _c(
                      "base-button",
                      {
                        staticClass: "bg-light fc-white",
                        attrs: {
                          "data-cy": "user-profile-authority-cancel-btn"
                        },
                        on: {
                          click: _vm.cancel,
                          keydown: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.cancel($event)
                          }
                        }
                      },
                      [_vm._v("\n          Cancel\n        ")]
                    )
                  ],
                  1
                )
              ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }