<template>
  <div
    class="UserProfileCompany column"
    data-cy="user-profile-company"
  >
    <div class="row row--justify-between">
      <label class="fs-18 fw-med mb-13">Company</label>
      <button
        @click="nowEditing"
        @keydown.enter="nowEditing"
        class="fc-light fs-14"
        data-cy="user-profile-company-edit-btn"
      >
        Edit
      </button>
    </div>

    <!-- BASE -->
    <transition
      mode="out-in"
      name="Transition__opacity-fade"
    >
      <div
        v-if="!editing"
        class="row row--wrap"
        key="base"
      >
        <template v-if="pagePOV === 'client'">
          <!-- Client legal, shortened, and DBA names should be as input by underwriter/client (unaltered) -->
          <!-- https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1639 -->
          <div class="column column--width-auto">
            <label class="fc-light fs-12 fw-med mb-7">LEGAL NAME</label>
            <label
              class="fs-14"
              id="Testing__ClientLegalName"
            >
              {{ user.name }}
            </label>
          </div>

          <div class="column column--width-auto">
            <label class="fc-light fs-12 fw-med mb-7">
              DBA
            </label>
            <label class="fs-14">
              {{ user.doing_business_as }}
            </label>
          </div>

          <div class="column column--width-auto">
            <label class="fc-light fs-12 fw-med mb-7">SHORTENED NAME</label>
            <label
              class="fs-14"
              id="Testing__ClientShortName"
            >
              {{ user.shortened_name }}
            </label>
          </div>

          <!-- Debtors do not have an EIN # -->
          <div class="column column--width-auto">
            <label class="fc-light fs-12 fw-med mb-7">EIN</label>
            <label class="fs-14">{{ user.ein }}</label>
          </div>

          <!-- Debtors do not have a corporation type -->
          <div class="column column--width-auto">
            <label class="fc-light fs-12 fw-med mb-7">TYPE</label>
            <label class="fs-14 capitalize">{{ user.corporation_type }}</label>
          </div>
        </template>

        <template v-if="pagePOV === 'debtor'">
          <div class="column column--width-auto">
            <!-- https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1639 -->
            <label class="fc-light fs-12 fw-med mb-7">LEGAL NAME</label>
            <label class="fs-14 uppercase">{{ filterAlgoliaDebtorName(this.user.name) }}</label>
          </div>

          <div class="UserProfileCompany__debtor-dbas column column--width-auto">
            <label class="fc-light fs-12 fw-med mb-7">DBA</label>
            <label class="fs-14 uppercase">
              {{
                user.doing_business_as
                  ? filterAlgoliaDebtorName(user.doing_business_as.join(', '))
                  : null
              }}
            </label>
          </div>

          <div class="column column--width-auto">
            <label class="fc-light fs-12 fw-med mb-7">BOBTAIL STATUS</label>
            <label :class="['capitalize fs-14', { 'fc-red': user.status === 'inactive' }]">
              {{ user.status }}
            </label>
          </div>
        </template>

        <div class="column column--width-auto">
          <label class="fc-light fs-12 fw-med mb-7">ADDRESS</label>
          <label class="fs-14">{{ user.address }}</label>
          <!-- START: Do not display these labels, even empty, if address_2 DNE -->
          <label
            v-if="user.address_2"
            class="fs-14"
          >
            {{ user.address_2 }}
          </label>
          <!-- END-->
          <label class="fs-14">{{ user.city }}, {{ user.state }} {{ user.zip }}</label>
        </div>

        <div class="column column--width-auto">
          <label class="fc-light fs-12 fw-med mb-7">PHONE</label>
          <label class="fs-14">{{ user.phone }}</label>
        </div>
      </div>


      <!-- EDIT -->
      <div
        v-else
        class="column"
        key="edit"
      >
        <div class="row row--wrap">
          <template v-if="pagePOV === 'client'">
            <base-input
              v-model.trim="duplicateUser.legalName.value"
              class="mr-6 mb-5"
              data-cy="user-profile-company-legal-name-input"
              :label="true"
              :label-for="'legal-name'"
              :instructions="instructions.LEGAL_NAME_INSTRUCTION"
              :valid="duplicateUser.legalName.valid"
            >
              Legal Name
            </base-input>
            <base-input
              v-model.trim="duplicateUser.dbas.value"
              class="mr-6 mb-5"
              data-cy="user-profile-company-dba-input"
              :label="true"
              :label-for="'doing-business-as'"
              :instructions="instructions.DBA_CLIENT_INSTRUCTION"
              :valid="duplicateUser.dbas.valid"
            >
              DBA
            </base-input>
            <base-input
              v-model.trim="duplicateUser.shortenedName.value"
              class="mr-6 mb-5"
              data-cy="user-profile-company-shortened-name-input"
              :label="true"
              :label-for="'shortened-name'"
              :instructions="instructions.SHORTENED_NAME_INSTRUCTION"
              :valid="duplicateUser.shortenedName.valid"
            >
              Shortened Name
            </base-input>
            <base-input
              v-model.trim="duplicateUser.ein.value"
              class="mr-6 mb-5"
              data-cy="user-profile-company-ein-input"
              :label="true"
              :label-for="'ein'"
              :instructions="instructions.EIN_INSTRUCTION"
              :valid="duplicateUser.ein.valid"
            >
              EIN
            </base-input>
            <div class="UserProfileCompany__select column mb-5 mr-6">
              <label
                class="fc-light fs-12 fw-med mb-7 uppercase"
                for="corporation-type"
              >
                Corporation Type
              </label>
              <v-select
                v-model="duplicateUser.corporationType.value"
                @input="duplicateUser.corporationType.value = $event"
                class="UserProfileCompany__type-select"
                :clearable="false"
                data-cy="user-profile-company-type-select"
                :options="corpTypeOptions"
              />
            </div>
          </template>

          <template v-if="pagePOV === 'debtor'">
            <base-input
              v-model.trim="duplicateUser.legalName.value"
              class="mr-6 mb-5"
              data-cy="user-profile-company-legal-name-input"
              :label="true"
              :label-for="'name'"
              :instructions="instructions.SHORTENED_NAME_INSTRUCTION"
              :valid="duplicateUser.legalName.valid"
            >
              LEGAL NAME
            </base-input>
            <base-input
              v-model.trim="duplicateUser.dbas.value"
              class="mr-6 mb-5"
              data-cy="user-profile-company-dba-input"
              :label="true"
              :label-for="'doing-business-as'"
              :instructions="instructions.DBA_DEBTOR_INSTRUCTION"
              :valid="duplicateUser.dbas.valid"
            >
              DBA
            </base-input>
            <div class="UserProfileCompany__status-container column mb-12">
              <label
                class="fc-light fs-12"
                for="bobtail-status"
              >
                Bobtail Status
              </label>
              <!-- BOBTAIL STATUS  -->
              <v-select
                v-model="duplicateUser.status.value"
                aria-label="Bobtail Status Select Input"
                class="UserProfileCompany__bobtailstatus-select mt-7"
                :clearable="false"
                data-cy="user-profile-company-status-select"
                :options="bobtailStatusOptions"
                :placeholder="'Bobtail Status'"
              />
            </div>
          </template>

          <base-input
            v-model.trim="duplicateUser.address.value"
            class="mr-5 mb-5"
            data-cy="user-profile-company-address-input"
            :label="true"
            :label-for="'address'"
            :instructions="instructions.ADDRESS_INSTRUCTION"
            :placeholder="'Street address'"
            :valid="duplicateUser.address.valid"
          >
            Address
          </base-input>
          <base-input
            v-model.trim="duplicateUser.address_2.value"
            class="mr-5 mb-5"
            data-cy="user-profile-company-address-2-input"
            :label="true"
            :label-for="'address 2'"
            :instructions="instructions.ADDRESS_2_INSTRUCTION"
            :placeholder="'Suite #'"
            :valid="duplicateUser.address_2.valid"
          >
            Address 2
          </base-input>
          <base-input
            v-model.trim="duplicateUser.city.value"
            class="mr-5 mb-5"
            data-cy="user-profile-company-city-input"
            :label="true"
            :label-for="'city'"
            :instructions="instructions.CITY_INSTRUCTION"
            :placeholder="'City'"
            :valid="duplicateUser.city.valid"
          >
            City
          </base-input>
          <div class="UserProfileCompany__select-container column mb-12">
            <label
              class="BaseInput__label uppercase"
              for="state"
            >
              State
            </label>
            <v-select
              v-model="duplicateUser.state.value"
              aria-label="Select input to choose state"
              :class="['UserProfileCompany__select', {
                'UserProfileCompany__select--invalid': !duplicateUser.state.valid
              }]"
              :clearable="false"

              data-cy="user-profile-company-state-select"
              :instructions="instructions.STATE_INSTRUCTION"
              :options="duplicateUser.state.options"
              :placeholder="'State:'"
            />
          </div>
          <base-input
            v-model.trim="duplicateUser.zip.value"
            class="mr-5 mb-5"
            data-cy="user-profile-company-zip-input"
            :label="true"
            :label-for="'zip'"
            :instructions="instructions.ZIP_INSTRUCTION"
            :placeholder="'Zip'"
            :valid="duplicateUser.zip.valid"
          >
            Zip
          </base-input>
          <base-input
            v-model.trim="duplicateUser.phone.value"
            class="mb-5"
            data-cy="user-profile-company-phone-input"
            :label="true"
            :label-for="'phone'"
            :instructions="(
              duplicateUser.phone.connected
                ? instructions.PHONE_INSTRUCTION : instructions.PHONE_CONNECTED_INSTRUCTION
            )"
            :placeholder="'Phone'"
            :valid="duplicateUser.phone.valid && duplicateUser.phone.connected"
          >
            Phone
          </base-input>
        </div>

        <div class="row mt-6">
          <base-button
            @click="saveUserDetails"
            @keydown.enter="saveUserDetails"
            class="bg-blue fc-white mr-6"
            data-cy="user-profile-company-save-btn"
          >
            Save
          </base-button>
          <base-button
            @click="cancel"
            @keydown.enter="cancel"
            class="bg-light fc-white"
            data-cy="user-profile-company-cancel-btn"
          >
            Cancel
          </base-button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// Helpers
import {
  Broker,
  Client,
  Debtor
} from '../utils/api'
import { statesAbbreviations } from '../utils/constants'
import {
  formatPhoneNumber,
  sanitizeAlgoliaNames
} from '../utils/helpers'
// Components
import BaseButton from './base-button.vue'
import BaseInput from './base-input.vue'
// Mixins
import {
  ValidationMixin
} from '../utils/validation-mixin'

export default {
  name: 'UserProfileCompany',

  components: {
    BaseButton,
    BaseInput,
  },

  mixins: [ValidationMixin],

  props: {
    pagePOV: {
      type: String,
      required: true,
    },
  },

  data () {
    return {
      bobtailStatusOptions: [
        'active',
        'sandbox',
        'inactive',
      ],
      corpTypeOptions: [
        'llc',
        'corp',
        'sole proprietor',
        'partnership',
      ],
      editing: false,
      duplicateUser: null,
    }
  },

  computed: {
    user () {
      // return client details if true; return debtor details
      if (this.pagePOV === 'client') {
        return this.$store.getters.client
      }
      return this.$store.getters.debtor
    },
  },

  methods: {
    cancel () {
      this.editing = false
      this.duplicateUser = null
    },

    dataReset () {
      Object.assign(this.$data, this.$options.data())
    },

    // Remove <em></em> from debtor name
    // https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1694
    filterAlgoliaDebtorName (name) {
      return sanitizeAlgoliaNames(name)
    },

    async isPhoneConnected () {
      this.progressStart()
      // ONLY check phone numbers for being connected if everything else is good
      const phoneData = await this.checkPhoneNumber(this.duplicateUser.phone.value)
      this.progressFinish()
      return phoneData.phone_valid
    },

    nowEditing () {
      this.duplicateUser = {
        address: {
          valid: true,
          value: this.user.address,
        },
        address_2: {
          valid: true,
          value: this.user.address_2,
        },
        city: {
          valid: true,
          value: this.user.city,
        },
        state: {
          options: statesAbbreviations,
          valid: true,
          value: this.user.state,
        },
        zip: {
          valid: true,
          value: this.user.zip,
        },
        corporationType: {
          valid: true,
          value: this.pagePOV === 'client' ? this.user.corporation_type : null,
        },
        dbas: {
          valid: true,
          value:
            this.user.doing_business_as && typeof this.user.doing_business_as === 'object'
              ? this.user.doing_business_as.join(', ')
              : this.user.doing_business_as,
        },
        ein: {
          valid: true,
          value: this.pagePOV === 'client' ? this.user.ein : null,
        },
        legalName: {
          valid: true,
          // eslint-disable-next-line no-nested-ternary
          value: this.user.name
        },
        phone: {
          connected: true,
          valid: true,
          value: this.user.phone,
        },
        shortenedName: {
          valid: true,
          value: this.pagePOV === 'client'
            ? this.user.shortened_name
            : null,
        },
        status: { value: this.user.status },
      }
      this.editing = true
    },

    async saveUserDetails () {
      if (!await this.validation()) return

      this.progressStart()

      if (this.pagePOV === 'client') {
        try {
          const updatedClient = (await Client.update({
            address: this.duplicateUser.address.value,
            address_2: this.duplicateUser.address_2.value,
            city: this.duplicateUser.city.value,
            corporation_type: this.duplicateUser.corporationType.value,
            doing_business_as:
              this.duplicateUser.dbas.value
                ? this.duplicateUser.dbas.value
                : null,
            ein: this.duplicateUser.ein.value
              ? this.duplicateUser.ein.value
              : null,
            id: this.user.id,
            name: this.duplicateUser.legalName.value,
            phone: formatPhoneNumber(this.duplicateUser.phone.value),
            shortened_name: this.duplicateUser.shortenedName.value,
            state: this.duplicateUser.state.value,
            zip: this.duplicateUser.zip.value,
          })).data
          this.$store.commit('UPDATE_STORED_CLIENT', updatedClient)
          this.editing = false
          this.progressFinish()
        }
        catch (error) {
          this.captureSentryEvent(
            'User Company "Save Client Details" Error',
            {
              config: error.config,
              data: this.$data,
              details: error,
              props: this.$props,
              response: error.response,
            }
          )
          this.CError(error)
          this.requestFailure({ message: 'There was an issue updating the client' })
        }
      }

      if (this.pagePOV === 'debtor') {
        try {
          const updatedDebtor = (await Debtor.update({
            address: this.duplicateUser.address.value,
            address_2: this.duplicateUser.address_2.value,
            city: this.duplicateUser.city.value,
            state: this.duplicateUser.state.value,
            zip: this.duplicateUser.zip.value,
            doing_business_as:
              this.duplicateUser.dbas.value
                ? this.duplicateUser.dbas.value.toLowerCase().split(', ')
                : [],
            id: this.user.id,
            name: this.duplicateUser.legalName.value.toLowerCase(),
            phone: formatPhoneNumber(this.duplicateUser.phone.value),
            status: this.duplicateUser.status.value,
          })).data
          this.$store.commit('UPDATE_STORED_DEBTOR', updatedDebtor)
          if (this.user.broker) {
            if (this.duplicateUser.legalName.value !== this.user.name) {
              const broker = (await Broker.update({
                name: this.duplicateUser.legalName.value.toLowerCase(),
                broker_id: this.user.broker.id,
                debtor_id: this.user.id,
                id: this.user.broker.id,
              })).data
              this.$store.commit('UPDATE_STORED_DEBTOR', { broker })
            }
          }
          this.editing = false
          this.progressFinish()
        }
        catch (error) {
          this.captureSentryEvent(
            'User Company "Save Client Details" Error',
            {
              config: error.config,
              data: this.$data,
              details: error,
              props: this.$props,
              response: error.response,
            }
          )
          this.CError(error)
          this.requestFailure({ message: 'There was an issue updating the debtor' })
        }
      }
    },

    async validation () {
      let valid = true
      // client: shortened name, dbas (different check than debtor), ein, type
      // debtor: dbas (different check than client)
      // both: phone, legal name, address, address_2, city, state, zip

      if (this.pagePOV === 'client') {
        // shortened name
        if (
          !this.duplicateUser.shortenedName.value
          || !this.isValidShortenedName(this.duplicateUser.shortenedName.value)
        ) {
          this.$set(this.duplicateUser.shortenedName, 'valid', false)
          valid = false
        } else {
          this.$set(this.duplicateUser.shortenedName, 'valid', true)
        }

        // DBAs
        if (
          this.duplicateUser.dbas.value
          && !this.isValidDBAClient(this.duplicateUser.dbas.value)
        ) {
          this.$set(this.duplicateUser.dbas, 'valid', false)
          valid = false
        } else {
          this.$set(this.duplicateUser.dbas, 'valid', true)
        }

        // ein
        if (
          this.duplicateUser.ein.value && !this.isValidEIN(this.duplicateUser.ein.value)
        ) {
          this.$set(this.duplicateUser.ein, 'valid', false)
          valid = false
        } else {
          this.$set(this.duplicateUser.ein, 'valid', true)
        }

        // corporation type
        if (
          !this.duplicateUser.corporationType.value
          || !this.isValidCorpType(this.duplicateUser.corporationType.value)
        ) {
          this.$set(this.duplicateUser.corporationType, 'valid', false)
          valid = false
        } else {
          this.$set(this.duplicateUser.corporationType, 'valid', true)
        }
      }

      if (this.pagePOV === 'debtor') {
        if (
          this.duplicateUser.dbas.value
          && !this.isValidDBADebtor(this.duplicateUser.dbas.value)
        ) {
          this.$set(this.duplicateUser.dbas, 'valid', false)
          valid = false
        } else {
          this.$set(this.duplicateUser.dbas, 'valid', true)
        }
      }

      // Phone
      if (
        !this.duplicateUser.phone.value
        || !this.isValidPhone(this.duplicateUser.phone.value)
      ) {
        this.$set(this.duplicateUser.phone, 'valid', false)
        valid = false
      } else {
        this.$set(this.duplicateUser.phone, 'valid', true)
      }
      if (!await this.isPhoneConnected()) {
        this.$set(this.duplicateUser.phone, 'connected', false)
        valid = false
      } else {
        this.$set(this.duplicateUser.phone, 'connected', true)
      }

      // Legal name
      // Client and Debtor legal name are not validated the same way
      const userNameValidationFunction = this.pagePOV === 'client'
        ? 'isValidLegalName'
        : 'isValidShortenedName'
      if (
        !this.duplicateUser.legalName.value
        || !this[userNameValidationFunction](this.duplicateUser.legalName.value)
      ) {
        this.$set(this.duplicateUser.legalName, 'valid', false)
        valid = false
      } else {
        this.$set(this.duplicateUser.legalName, 'valid', true)
      }

      // Address - /, -, . allowed
      if (!this.isValidAddress(this.duplicateUser.address.value)) {
        this.$set(this.duplicateUser.address, 'valid', false)
        valid = false
      } else {
        this.$set(this.duplicateUser.address, 'valid', true)
      }
      if (this.duplicateUser.address_2.value) {
        if (!this.isValidAddress(this.duplicateUser.address_2.value)) {
          this.$set(this.duplicateUser.address_2, 'valid', false)
          valid = false
        } else {
          this.$set(this.duplicateUser.address_2, 'valid', true)
        }
      } else {
        this.$set(this.duplicateUser.address_2, 'valid', true)
      }

      if (!this.isValidCity(this.duplicateUser.city.value)) {
        this.$set(this.duplicateUser.city, 'valid', false)
        valid = false
      } else {
        this.$set(this.duplicateUser.city, 'valid', true)
      }

      if (!this.isValidState(this.duplicateUser.state.value)) {
        this.$set(this.duplicateUser.state, 'valid', false)
        valid = false
      } else {
        this.$set(this.duplicateUser.state, 'valid', true)
      }

      if (!this.isValidZip(this.duplicateUser.zip.value)) {
        this.$set(this.duplicateUser.zip, 'valid', false)
        valid = false
      } else {
        this.$set(this.duplicateUser.zip, 'valid', true)
      }

      return valid
    },
  }
}
</script>

<style lang="sass">
.UserProfileCompany

  .BaseInput,
  .v-select
    width: rem(210px)

  .column--width-auto
    margin-right: rem(37px)

    &:last-child
      margin-right: 0

  &__bobtailstatus-select
    width: rem(150px)

  &__debtor-dbas
    max-width: rem(350px)

  &__select
    width: auto

    &--invalid
      border: $border
      border-color: $danger
      border-radius: $border-radius

  &__select-container,
  &__status-container
    width: rem(215px)

  &__type-select
    .vs__selected
      &::before
        content: 'Type:'
        padding-right: rem(4px)
</style>
