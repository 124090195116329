<template>
  <div
    class="UserProfileAuthority column"
    data-cy="user-profile-authority"
  >
    <div class="row row--justify-between">
      <label class="fs-18 fw-med mb-13">Authority</label>
      <button
        @click="nowEditing"
        @keydown.enter="nowEditing"
        class="fc-light fs-14"
        data-cy="user-profile-authority-edit-btn"
      >
        Edit
      </button>
    </div>

    <transition
      mode="out-in"
      name="Transition__opacity-fade"
    >
      <div
        v-if="!editing"
        class="row row--wrap"
        key="base"
      >
        <!-- MC -->
        <div class="column column--width-auto">
          <label class="fc-light fs-12 fw-med mb-7">MC</label>
          <label class="fs-14 uppercase">
            {{ pagePOV === 'client' ? user.mc || 'N/A' : user.broker.mc }}
          </label>
        </div>
        <!-- DOT -->
        <div class="column column--width-auto">
          <label class="fc-light fs-12 fw-med mb-7">DOT</label>
          <label class="fs-14 uppercase">
            {{ pagePOV === 'client' ? user.dot : user.broker.dot }}
          </label>
        </div>
        <!-- AUTHORITY DATE -->
        <div class="column column--width-auto">
          <label class="fc-light fs-12 fw-med mb-7">AUTHORITY DATE</label>
          <label class="fs-14">{{ authorityDate }}</label>
        </div>
        <!-- AUTHORITY STATUS (DEBTOR ONLY) -->
        <div
          v-if="pagePOV === 'debtor'"
          class="column column--width-auto"
        >
          <label class="fc-light fs-12 fw-med mb-7">AUTHORITY STATUS</label>
          <label :class="['capitalize fs-14', { 'fc-red': user.broker.authority_status === 'inactive' }]">
            {{ user.broker.authority_status }}
          </label>
        </div>
        <!-- EMAIL (CLIENT ONLY) -->
        <div
          v-if="pagePOV === 'client'"
          class="column column--width-auto"
        >
          <label class="fc-light fs-12 fw-med mb-7">EMAIL</label>
          <label class="fs-14">{{ user.email }}</label>
        </div>
      </div>


      <!-- EDIT -->
      <div
        v-else
        class="column"
        key="edit"
      >
        <div class="row row--wrap">
          <!-- AUTHORITY DATE -->
          <div class="mr-6">
            <label
              class="fc-light fs-12"
              for="authority-date"
            >
              {{
                duplicateUser.authority_date.valid
                  ? 'Authority Date'
                  : instructions.AUTHORITY_DATE_INSTRUCTION
              }}
            </label>
            <base-date-picker
              v-model="duplicateUser.authority_date.value"
              class="mt-7"
              id="authority-date"
              :valid="duplicateUser.authority_date.valid"
            />
          </div>
          <!-- AUTHORITY STATUS (debtor only) -->
          <div
            v-if="pagePOV === 'debtor'"
            class="mr-6"
          >
            <label
              class="fc-light fs-12"
              for="authority-status"
            >
              Authority Status
            </label>
            <!-- AUTHORITY STATUS  -->
            <v-select
              v-model="duplicateUser.authority_status.value"
              aria-label="Authority Status Select Input"
              class="UserProfileAuthority__authority-select mt-7"
              :clearable="false"
              data-cy="user-profile-authority-authority-status"
              :options="authorityStatusOptions"
              :placeholder="'Authority Status'"
            />
          </div>
          <!-- EMAIL (CLIENT ONLY) -->
          <div v-if="pagePOV === 'client'">
            <base-input
              v-model.trim="duplicateUser.email.value"
              class="mr-6"
              :instructions="
                duplicateUser.email.unique
                  ? instructions.EMAIL_INSTRUCTION
                  : instructions.EMAIL_UNIQUE_INSTRUCTION
              "
              :label="true"
              :label-for="'email'"
              :valid="duplicateUser.email.unique && duplicateUser.email.valid"
            >
              Email
            </base-input>
          </div>
        </div>

        <div class="row mt-12">
          <base-button
            @click="saveUserDetails"
            @keydown.enter="saveUserDetails"
            class="bg-blue fc-white mr-6"
            data-cy="user-profile-authority-save-btn"
          >
            Save
          </base-button>
          <base-button
            @click="cancel"
            @keydown.enter="cancel"
            class="bg-light fc-white"
            data-cy="user-profile-authority-cancel-btn"
          >
            Cancel
          </base-button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// Packages
import moment from 'moment'
// Helpers
import {
  Broker,
  Client,
} from '../utils/api'
// Components
import BaseButton from './base-button.vue'
import BaseDatePicker from './base-date-picker.vue'
import BaseInput from './base-input.vue'
// Mixins
import {
  ValidationMixin
} from '../utils/validation-mixin'

export default {
  name: 'UserProfileAuthority',

  components: {
    BaseButton,
    BaseDatePicker,
    BaseInput,
  },

  mixins: [ValidationMixin],

  props: {
    pagePOV: {
      type: String,
      required: true,
    },
  },

  data () {
    return {
      authorityStatusOptions: [
        'active',
        'inactive',
      ],
      editing: false,
      duplicateUser: null,
    }
  },

  computed: {
    authorityDate () {
      let displayedDate = null

      // Migrated users may not have an authority date
      if (this.pagePOV === 'client') {
        if (!this.user.authority_date) displayedDate = null
        else displayedDate = moment(this.user.authority_date).format('M-D-YYYY')
      }
      if (this.pagePOV === 'debtor') {
        if (!this.user.broker.authority_date) displayedDate = null
        else displayedDate = moment(this.user.broker.authority_date).format('M-D-YYYY')
      }
      return displayedDate
    },

    user () {
      // return client details if true; return debtor details
      if (this.pagePOV === 'client') {
        return this.$store.getters.client
      }
      return this.$store.getters.debtor
    },
  },

  methods: {
    cancel () {
      this.editing = false
      this.duplicateUser = null
    },

    dataReset () {
      Object.assign(this.$data, this.$options.data())
    },

    moment, // Need to format date in HTML due to filters in expressions disallowed

    nowEditing () {
      if (this.pagePOV === 'client') {
        this.duplicateUser = {
          authority_date: {
            valid: true,
            // Migrated users may not have an authority date
            value: this.user.authority_date ? new Date(moment(this.user.authority_date)) : null,
          },
          email: {
            unique: true,
            valid: true,
            value: this.user.email,
          },
        }
      }
      if (this.pagePOV === 'debtor') {
        this.duplicateUser = {
          authority_date: {
            valid: true,
            // Migrated users may not have an authority date
            value: this.user.broker.authority_date ? new Date(moment(this.user.broker.authority_date)) : null,
          },
          authority_status: { value: this.user.broker.authority_status },
        }
      }
      this.editing = true
    },

    async saveUserDetails () {
      if (!await this.validation()) return

      this.progressStart()

      if (this.pagePOV === 'client') {
        try {
          const updatedClient = (await Client.update({
            authority_date: this.duplicateUser.authority_date.value,
            email: this.duplicateUser.email.value.toLowerCase(),
            id: this.user.id,
          })).data
          this.$store.commit('UPDATE_STORED_CLIENT', updatedClient)
        }
        catch (error) {
          this.captureSentryEvent(
            'User Authority "Save Client Details" Error',
            {
              config: error.config,
              data: this.$data,
              details: error,
              props: this.$props,
              response: error.response,
            }
          )
          this.CError(error)
          this.requestFailure({ message: `There was an issue updating the client: ${error.message}` })
        }
      }
      if (this.pagePOV === 'debtor') {
        // Only brokers have an authority section
        try {
          const broker = (await Broker.update({
            authority_date: this.duplicateUser.authority_date.value,
            authority_status: this.duplicateUser.authority_status.value,
            debtor_id: this.user.id,
            id: this.user.broker.id,
          })).data
          this.$store.commit('UPDATE_STORED_DEBTOR', { broker })
        }
        catch (error) {
          this.captureSentryEvent(
            'User Authority "Save Debtor Broker Details" Error',
            {
              config: error.config,
              data: this.$data,
              details: error,
              props: this.$props,
              response: error.response,
            }
          )
          this.CError(error)
          this.requestFailure({ message: 'There was an issue updating the debtor' })
        }
      }

      this.editing = false
      this.progressFinish()
    },

    async validation () {
      if (
        this.duplicateUser.authority_date.value
        && !this.isValidAuthorityDate(this.duplicateUser.authority_date.value)
      ) {
        this.$set(this.duplicateUser.authority_date, 'valid', false)
        return false
      }
      this.$set(this.duplicateUser.authority_date, 'valid', true)

      if (this.pagePOV === 'client') {
        // Check that email is valid
        if (!this.isValidEmail(this.duplicateUser.email.value)) {
          this.$set(this.duplicateUser.email, 'valid', false)
          return false
        }
        this.$set(this.duplicateUser.email, 'valid', true)

        // Check that email is unique
        // Don't waste resources validating uniqueness (asynchronous) until the email is valid
        if (this.duplicateUser.email.valid) {
          try {
            const isEmailUnique = (await Client.uniqueField({
              useremail: this.duplicateUser.email.value
            })).data
            if (!isEmailUnique) {
              this.$set(this.duplicateUser.email, 'unique', false)
              return false
            }
            this.$set(this.duplicateUser.email, 'unique', true)
          }
          catch (error) {
            this.captureSentryEvent(
              'Save User Details "Unique Email"',
              {
                config: error.config,
                data: this.$data,
                details: error,
                props: this.$props,
                response: error.response,
              }
            )
            // let the api error messages handle it
            this.CError(error)
            this.$set(this.duplicateUser.email, 'unique', false)
            return false
          }
        }
      }

      return true
    },
  }
}
</script>

<style lang="sass">
.UserProfileAuthority

  .column--width-auto
    margin-right: rem(37px)

    &:last-child
      margin-right: 0

  &__authority-select
    width: rem(150px)
</style>