var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "UserProfileCompany column",
      attrs: { "data-cy": "user-profile-company" }
    },
    [
      _c("div", { staticClass: "row row--justify-between" }, [
        _c("label", { staticClass: "fs-18 fw-med mb-13" }, [_vm._v("Company")]),
        _c(
          "button",
          {
            staticClass: "fc-light fs-14",
            attrs: { "data-cy": "user-profile-company-edit-btn" },
            on: {
              click: _vm.nowEditing,
              keydown: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.nowEditing($event)
              }
            }
          },
          [_vm._v("\n      Edit\n    ")]
        )
      ]),
      _c(
        "transition",
        { attrs: { mode: "out-in", name: "Transition__opacity-fade" } },
        [
          !_vm.editing
            ? _c(
                "div",
                { key: "base", staticClass: "row row--wrap" },
                [
                  _vm.pagePOV === "client"
                    ? [
                        _c(
                          "div",
                          { staticClass: "column column--width-auto" },
                          [
                            _c(
                              "label",
                              { staticClass: "fc-light fs-12 fw-med mb-7" },
                              [_vm._v("LEGAL NAME")]
                            ),
                            _c(
                              "label",
                              {
                                staticClass: "fs-14",
                                attrs: { id: "Testing__ClientLegalName" }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.user.name) +
                                    "\n          "
                                )
                              ]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "column column--width-auto" },
                          [
                            _c(
                              "label",
                              { staticClass: "fc-light fs-12 fw-med mb-7" },
                              [_vm._v("\n            DBA\n          ")]
                            ),
                            _c("label", { staticClass: "fs-14" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.user.doing_business_as) +
                                  "\n          "
                              )
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "column column--width-auto" },
                          [
                            _c(
                              "label",
                              { staticClass: "fc-light fs-12 fw-med mb-7" },
                              [_vm._v("SHORTENED NAME")]
                            ),
                            _c(
                              "label",
                              {
                                staticClass: "fs-14",
                                attrs: { id: "Testing__ClientShortName" }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.user.shortened_name) +
                                    "\n          "
                                )
                              ]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "column column--width-auto" },
                          [
                            _c(
                              "label",
                              { staticClass: "fc-light fs-12 fw-med mb-7" },
                              [_vm._v("EIN")]
                            ),
                            _c("label", { staticClass: "fs-14" }, [
                              _vm._v(_vm._s(_vm.user.ein))
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "column column--width-auto" },
                          [
                            _c(
                              "label",
                              { staticClass: "fc-light fs-12 fw-med mb-7" },
                              [_vm._v("TYPE")]
                            ),
                            _c("label", { staticClass: "fs-14 capitalize" }, [
                              _vm._v(_vm._s(_vm.user.corporation_type))
                            ])
                          ]
                        )
                      ]
                    : _vm._e(),
                  _vm.pagePOV === "debtor"
                    ? [
                        _c(
                          "div",
                          { staticClass: "column column--width-auto" },
                          [
                            _c(
                              "label",
                              { staticClass: "fc-light fs-12 fw-med mb-7" },
                              [_vm._v("LEGAL NAME")]
                            ),
                            _c("label", { staticClass: "fs-14 uppercase" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.filterAlgoliaDebtorName(this.user.name)
                                )
                              )
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "UserProfileCompany__debtor-dbas column column--width-auto"
                          },
                          [
                            _c(
                              "label",
                              { staticClass: "fc-light fs-12 fw-med mb-7" },
                              [_vm._v("DBA")]
                            ),
                            _c("label", { staticClass: "fs-14 uppercase" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.user.doing_business_as
                                      ? _vm.filterAlgoliaDebtorName(
                                          _vm.user.doing_business_as.join(", ")
                                        )
                                      : null
                                  ) +
                                  "\n          "
                              )
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "column column--width-auto" },
                          [
                            _c(
                              "label",
                              { staticClass: "fc-light fs-12 fw-med mb-7" },
                              [_vm._v("BOBTAIL STATUS")]
                            ),
                            _c(
                              "label",
                              {
                                class: [
                                  "capitalize fs-14",
                                  { "fc-red": _vm.user.status === "inactive" }
                                ]
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.user.status) +
                                    "\n          "
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    : _vm._e(),
                  _c("div", { staticClass: "column column--width-auto" }, [
                    _c("label", { staticClass: "fc-light fs-12 fw-med mb-7" }, [
                      _vm._v("ADDRESS")
                    ]),
                    _c("label", { staticClass: "fs-14" }, [
                      _vm._v(_vm._s(_vm.user.address))
                    ]),
                    _vm.user.address_2
                      ? _c("label", { staticClass: "fs-14" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.user.address_2) +
                              "\n        "
                          )
                        ])
                      : _vm._e(),
                    _c("label", { staticClass: "fs-14" }, [
                      _vm._v(
                        _vm._s(_vm.user.city) +
                          ", " +
                          _vm._s(_vm.user.state) +
                          " " +
                          _vm._s(_vm.user.zip)
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "column column--width-auto" }, [
                    _c("label", { staticClass: "fc-light fs-12 fw-med mb-7" }, [
                      _vm._v("PHONE")
                    ]),
                    _c("label", { staticClass: "fs-14" }, [
                      _vm._v(_vm._s(_vm.user.phone))
                    ])
                  ])
                ],
                2
              )
            : _c("div", { key: "edit", staticClass: "column" }, [
                _c(
                  "div",
                  { staticClass: "row row--wrap" },
                  [
                    _vm.pagePOV === "client"
                      ? [
                          _c(
                            "base-input",
                            {
                              staticClass: "mr-6 mb-5",
                              attrs: {
                                "data-cy":
                                  "user-profile-company-legal-name-input",
                                label: true,
                                "label-for": "legal-name",
                                instructions:
                                  _vm.instructions.LEGAL_NAME_INSTRUCTION,
                                valid: _vm.duplicateUser.legalName.valid
                              },
                              model: {
                                value: _vm.duplicateUser.legalName.value,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.duplicateUser.legalName,
                                    "value",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "duplicateUser.legalName.value"
                              }
                            },
                            [_vm._v("\n            Legal Name\n          ")]
                          ),
                          _c(
                            "base-input",
                            {
                              staticClass: "mr-6 mb-5",
                              attrs: {
                                "data-cy": "user-profile-company-dba-input",
                                label: true,
                                "label-for": "doing-business-as",
                                instructions:
                                  _vm.instructions.DBA_CLIENT_INSTRUCTION,
                                valid: _vm.duplicateUser.dbas.valid
                              },
                              model: {
                                value: _vm.duplicateUser.dbas.value,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.duplicateUser.dbas,
                                    "value",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "duplicateUser.dbas.value"
                              }
                            },
                            [_vm._v("\n            DBA\n          ")]
                          ),
                          _c(
                            "base-input",
                            {
                              staticClass: "mr-6 mb-5",
                              attrs: {
                                "data-cy":
                                  "user-profile-company-shortened-name-input",
                                label: true,
                                "label-for": "shortened-name",
                                instructions:
                                  _vm.instructions.SHORTENED_NAME_INSTRUCTION,
                                valid: _vm.duplicateUser.shortenedName.valid
                              },
                              model: {
                                value: _vm.duplicateUser.shortenedName.value,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.duplicateUser.shortenedName,
                                    "value",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "duplicateUser.shortenedName.value"
                              }
                            },
                            [_vm._v("\n            Shortened Name\n          ")]
                          ),
                          _c(
                            "base-input",
                            {
                              staticClass: "mr-6 mb-5",
                              attrs: {
                                "data-cy": "user-profile-company-ein-input",
                                label: true,
                                "label-for": "ein",
                                instructions: _vm.instructions.EIN_INSTRUCTION,
                                valid: _vm.duplicateUser.ein.valid
                              },
                              model: {
                                value: _vm.duplicateUser.ein.value,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.duplicateUser.ein,
                                    "value",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "duplicateUser.ein.value"
                              }
                            },
                            [_vm._v("\n            EIN\n          ")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "UserProfileCompany__select column mb-5 mr-6"
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "fc-light fs-12 fw-med mb-7 uppercase",
                                  attrs: { for: "corporation-type" }
                                },
                                [
                                  _vm._v(
                                    "\n              Corporation Type\n            "
                                  )
                                ]
                              ),
                              _c("v-select", {
                                staticClass: "UserProfileCompany__type-select",
                                attrs: {
                                  clearable: false,
                                  "data-cy": "user-profile-company-type-select",
                                  options: _vm.corpTypeOptions
                                },
                                on: {
                                  input: function($event) {
                                    _vm.duplicateUser.corporationType.value = $event
                                  }
                                },
                                model: {
                                  value:
                                    _vm.duplicateUser.corporationType.value,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.duplicateUser.corporationType,
                                      "value",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "duplicateUser.corporationType.value"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      : _vm._e(),
                    _vm.pagePOV === "debtor"
                      ? [
                          _c(
                            "base-input",
                            {
                              staticClass: "mr-6 mb-5",
                              attrs: {
                                "data-cy":
                                  "user-profile-company-legal-name-input",
                                label: true,
                                "label-for": "name",
                                instructions:
                                  _vm.instructions.SHORTENED_NAME_INSTRUCTION,
                                valid: _vm.duplicateUser.legalName.valid
                              },
                              model: {
                                value: _vm.duplicateUser.legalName.value,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.duplicateUser.legalName,
                                    "value",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "duplicateUser.legalName.value"
                              }
                            },
                            [_vm._v("\n            LEGAL NAME\n          ")]
                          ),
                          _c(
                            "base-input",
                            {
                              staticClass: "mr-6 mb-5",
                              attrs: {
                                "data-cy": "user-profile-company-dba-input",
                                label: true,
                                "label-for": "doing-business-as",
                                instructions:
                                  _vm.instructions.DBA_DEBTOR_INSTRUCTION,
                                valid: _vm.duplicateUser.dbas.valid
                              },
                              model: {
                                value: _vm.duplicateUser.dbas.value,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.duplicateUser.dbas,
                                    "value",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "duplicateUser.dbas.value"
                              }
                            },
                            [_vm._v("\n            DBA\n          ")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "UserProfileCompany__status-container column mb-12"
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "fc-light fs-12",
                                  attrs: { for: "bobtail-status" }
                                },
                                [
                                  _vm._v(
                                    "\n              Bobtail Status\n            "
                                  )
                                ]
                              ),
                              _c("v-select", {
                                staticClass:
                                  "UserProfileCompany__bobtailstatus-select mt-7",
                                attrs: {
                                  "aria-label": "Bobtail Status Select Input",
                                  clearable: false,
                                  "data-cy":
                                    "user-profile-company-status-select",
                                  options: _vm.bobtailStatusOptions,
                                  placeholder: "Bobtail Status"
                                },
                                model: {
                                  value: _vm.duplicateUser.status.value,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.duplicateUser.status,
                                      "value",
                                      $$v
                                    )
                                  },
                                  expression: "duplicateUser.status.value"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      : _vm._e(),
                    _c(
                      "base-input",
                      {
                        staticClass: "mr-5 mb-5",
                        attrs: {
                          "data-cy": "user-profile-company-address-input",
                          label: true,
                          "label-for": "address",
                          instructions: _vm.instructions.ADDRESS_INSTRUCTION,
                          placeholder: "Street address",
                          valid: _vm.duplicateUser.address.valid
                        },
                        model: {
                          value: _vm.duplicateUser.address.value,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.duplicateUser.address,
                              "value",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "duplicateUser.address.value"
                        }
                      },
                      [_vm._v("\n          Address\n        ")]
                    ),
                    _c(
                      "base-input",
                      {
                        staticClass: "mr-5 mb-5",
                        attrs: {
                          "data-cy": "user-profile-company-address-2-input",
                          label: true,
                          "label-for": "address 2",
                          instructions: _vm.instructions.ADDRESS_2_INSTRUCTION,
                          placeholder: "Suite #",
                          valid: _vm.duplicateUser.address_2.valid
                        },
                        model: {
                          value: _vm.duplicateUser.address_2.value,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.duplicateUser.address_2,
                              "value",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "duplicateUser.address_2.value"
                        }
                      },
                      [_vm._v("\n          Address 2\n        ")]
                    ),
                    _c(
                      "base-input",
                      {
                        staticClass: "mr-5 mb-5",
                        attrs: {
                          "data-cy": "user-profile-company-city-input",
                          label: true,
                          "label-for": "city",
                          instructions: _vm.instructions.CITY_INSTRUCTION,
                          placeholder: "City",
                          valid: _vm.duplicateUser.city.valid
                        },
                        model: {
                          value: _vm.duplicateUser.city.value,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.duplicateUser.city,
                              "value",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "duplicateUser.city.value"
                        }
                      },
                      [_vm._v("\n          City\n        ")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "UserProfileCompany__select-container column mb-12"
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "BaseInput__label uppercase",
                            attrs: { for: "state" }
                          },
                          [_vm._v("\n            State\n          ")]
                        ),
                        _c("v-select", {
                          class: [
                            "UserProfileCompany__select",
                            {
                              "UserProfileCompany__select--invalid": !_vm
                                .duplicateUser.state.valid
                            }
                          ],
                          attrs: {
                            "aria-label": "Select input to choose state",
                            clearable: false,
                            "data-cy": "user-profile-company-state-select",
                            instructions: _vm.instructions.STATE_INSTRUCTION,
                            options: _vm.duplicateUser.state.options,
                            placeholder: "State:"
                          },
                          model: {
                            value: _vm.duplicateUser.state.value,
                            callback: function($$v) {
                              _vm.$set(_vm.duplicateUser.state, "value", $$v)
                            },
                            expression: "duplicateUser.state.value"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "base-input",
                      {
                        staticClass: "mr-5 mb-5",
                        attrs: {
                          "data-cy": "user-profile-company-zip-input",
                          label: true,
                          "label-for": "zip",
                          instructions: _vm.instructions.ZIP_INSTRUCTION,
                          placeholder: "Zip",
                          valid: _vm.duplicateUser.zip.valid
                        },
                        model: {
                          value: _vm.duplicateUser.zip.value,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.duplicateUser.zip,
                              "value",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "duplicateUser.zip.value"
                        }
                      },
                      [_vm._v("\n          Zip\n        ")]
                    ),
                    _c(
                      "base-input",
                      {
                        staticClass: "mb-5",
                        attrs: {
                          "data-cy": "user-profile-company-phone-input",
                          label: true,
                          "label-for": "phone",
                          instructions: _vm.duplicateUser.phone.connected
                            ? _vm.instructions.PHONE_INSTRUCTION
                            : _vm.instructions.PHONE_CONNECTED_INSTRUCTION,
                          placeholder: "Phone",
                          valid:
                            _vm.duplicateUser.phone.valid &&
                            _vm.duplicateUser.phone.connected
                        },
                        model: {
                          value: _vm.duplicateUser.phone.value,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.duplicateUser.phone,
                              "value",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "duplicateUser.phone.value"
                        }
                      },
                      [_vm._v("\n          Phone\n        ")]
                    )
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "row mt-6" },
                  [
                    _c(
                      "base-button",
                      {
                        staticClass: "bg-blue fc-white mr-6",
                        attrs: { "data-cy": "user-profile-company-save-btn" },
                        on: {
                          click: _vm.saveUserDetails,
                          keydown: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.saveUserDetails($event)
                          }
                        }
                      },
                      [_vm._v("\n          Save\n        ")]
                    ),
                    _c(
                      "base-button",
                      {
                        staticClass: "bg-light fc-white",
                        attrs: { "data-cy": "user-profile-company-cancel-btn" },
                        on: {
                          click: _vm.cancel,
                          keydown: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.cancel($event)
                          }
                        }
                      },
                      [_vm._v("\n          Cancel\n        ")]
                    )
                  ],
                  1
                )
              ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }